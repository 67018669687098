
@import "~digimaker-ui/digimaker-ui.css";

.App {

}

a{
  text-decoration: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.action-body{
  float: right;
  display: flex;
}

.action-body button{
  margin-left: 5px;
}

.action-title{
  text-align: right;
  font-weight: bold;
}

.field-mode-edit{
  margin-top: 10px;
}

.right{
  text-align: right;
}

.logo img{
  background: linear-gradient(125deg, #006f88, rgb(37, 110, 219));
  text-align: center;
  color: white;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
}

.logo{
  background-color: white;
  padding: 5px;
}

img, svg{
  vertical-align:baseline !important;
}

h2{
  font-size: 1.5rem;
  border-bottom: 1px solid #eeebeb;
  padding: 0px 0px 10px 0px;
  margin-bottom: 20px;
}

h3{
  font-size: 1.2rem;
}